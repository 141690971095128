import React from 'react';
import VideoPlayer from './VideoPlayer';
import Nav from './Nav';

const VideoChat = () => (
  <div className='vmr-container'>

    <Nav />
    <VideoPlayer />
  </div>
);

export default VideoChat;
