import React, { useState, useContext } from 'react';
import '../styles.css';
import { Button, TextField,Typography, Grid, Container, Paper } from '@material-ui/core';
import { SocketContext } from '../Context';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({



  notificationPopup: {
    position: 'absolute',
    zIndex: 300,
    width: 300,
    height:50,
    right: 10,
    top: 10,
    backgroundColor: 'lightGray',
    padding: 10
  },
}));

const NotificationPopup = () => {
  const {message} = useContext(SocketContext);
  const classes = useStyles();
  return (
    <Paper className={classes.notificationPopup}>
      {message}
    </Paper>
  );
}


export default NotificationPopup;
