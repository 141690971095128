import React, { createContext, useState, useRef, useEffect } from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import fetch from 'node-fetch';

const SocketContext = createContext();

const defaultPage = "http://rcm-vmr.s3-website-us-east-1.amazonaws.com/getVMR/";

//const socketURL = 'http://localhost:5000';
//const socketURL = 'https://jolly-mouse-56.loca.lt';
const socketURL = 'https://vmr-server.rcmtoolbox.com';


const path = window.location.pathname;


console.log('socketURL: ' + socketURL + " path: " + path);

//const socket = io('http://localhost:5000');
function showLog(str) {
  console.log(str);
}


// CHECK THE ID ===================
const postRequest = (json) => {
  showLog("[postRequest] json: " + JSON.stringify(json, null, 4))
  const ApiUrl = 'https://m0gaeymnpc.execute-api.us-east-1.amazonaws.com/dev/service';

  return fetch(ApiUrl, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json)
  })
  .then((response) => response.json())

  .then((responseJson) => {
    const body = responseJson["body"];
    showLog('body: ' + body);
    showLog('messagejson: ' + JSON.stringify(body));
    return body;
  })
  .catch((error) => {
    showLog("Error", JSON.stringify(error), "error");
    return error;
  });
}
// =====================================


const getRequest = async (api) => {
  showLog("[getRequest]")
  return fetch(api, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then((response) => response.json())

  .then((responseJson) => {
    showLog("[getRequest] responseJson: " + JSON.stringify(responseJson,null,4))
    return responseJson;
  })
  .catch((error) => {
    showLog("Error", JSON.stringify(error), "error");
    return error;
  });
}


function exitPage() {
  showLog("[exitPage]")
  window.location = defaultPage;
}

function validateRoomID() {
  showLog('[validateRoomID] ' + path);

  if (path === '/') {
    exitPage()
    return;
  } else {
    const roomID = path.replace('/', '');
    const json = {
        id: roomID
    }
    return postRequest(json)
  }
}

function checkPath() {
  showLog("[checkPath]")
  if (path.toString().includes('.') == false) {
    showLog("app getting room " + path)
    validateRoomID(path).then((result) => {
      if (result === true) {
        showLog("enter the room")
      } else {
        showLog("result " + result);
        exitPage()
      }
    }).catch((e) =>{
        showLog("caught error: " + e);
        exitPage()
    });
  }
}

const socket = io.connect(
  socketURL,
  {
    query: {
      room: path,
    }
  }
)

const ContextProvider = ({ children }) => {
  const [login, setLogin] = useState(false);

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState('');
  const [call, setCall] = useState({});
  const [me, setMe] = useState('');
  const [message, setMessage] = useState('');
  const [roomID, setRoomID] = useState('')
  const [recipientID, setRecipientID] = useState('')
  const [myIP, setMyIP] = useState(null)


  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

  //const clientRoom = '';

  useEffect(() => {
    showLog('[useEffect] Context.js ' + "path: " + path + " socket.id: " + socket.id);

    if (roomID==='') {
      // TEMP comment out checkPath to bypass ID validation *********
      //checkPath();
      // ************************************************************
      setRoomID(path);
    }

    if (myIP===null) {
      checkIP();
    }


      socket.on('getRoomInfo', (data)=> {
        showLog('on getRoomInfo: ' + data)
      })

      socket.on('setRecipientID', (id) => {
        showLog("on setRecipientID: id " +  id)

      })

      socket.on('me', (id) => {
        showLog(" on [me] id: " + id);
        setMe(id);
      });

      socket.on('callUser', ({ from, name: callerName, signal }) => {
        showLog('socket.on callUser in useEffect from: ' + from + " callerName: " + callerName + " signal: " + signal);
        setCall({ isReceivingCall: true, from, name: callerName, signal });
      });

      socket.on('callTheRecipient', (id) => {
        //showLog("on callTheRecipient: id " + id)
        setRecipientID(id)
        //showLog('recipientID: ' + recipientID)
        //callUser(id)
      })

      socket.on('removeMe', () => {
        showLog("on [removeMe]");
        leaveCall();
      });

      socket.on('message', (text) => {
        showLog("on [message] text: " + text);
        setMessage(text)
      });

      //socket.on('removeMe', leaveCall());


      if (recipientID !== '' && name !== '') {
        showLog("callUser " + recipientID + " name: " + name + " me: " + me);
        callUser(recipientID)
      }

  }, [recipientID, name]);

  const checkIP = async () => {
    showLog("[checkIP]")
    const ApiUrl = "https://api64.ipify.org?format=json"

    try {
    	const response = await fetch(ApiUrl, {
        method: 'get',
        headers: {'Content-Type': 'application/json'}
      });
    	const data = await response.json();
      showLog("data: " + JSON.stringify(data))
      setMyIP(data.ip)

    } catch (error) {
    	if (error instanceof fetch.AbortError) {
    		console.log('request was aborted');
    	}
    }


  }

  const LogMeIn = () => {
    showLog('[LogMeIn]');
    showLog("myIP: " + myIP + " my name: " + name);

    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((currentStream) => {
        setStream(currentStream);
        myVideo.current.srcObject = currentStream;
        socket.emit('logMeIn', roomID, myIP, name);
    });
  }

  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on('signal', (data) => {
      socket.emit('answerCall', { signal: data, to: call.from, room: roomID });
    });

    peer.on('stream', (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    showLog('[callUser] id: ' + id);
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on('signal', (data) => {
      showLog('peer.on(signal) callUser');
      socket.emit('callUser', { userToCall: id, signalData: data, from: me, name });
    });

    peer.on('stream', (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on('callAccepted', (signal) => {
      setCallAccepted(true);

      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    showLog("[leaveCall] connectionRef.current: " + connectionRef.current)
    if (connectionRef.current !== undefined) {
      connectionRef.current.destroy();
    }
    setCallEnded(true);
    exitPage();
  };

  return (
    <SocketContext.Provider value={{
      login,
      setLogin,
      call,
      callAccepted,
      myVideo,
      userVideo,
      stream,
      name,
      setName,
      message,
      setMessage,
      callEnded,
      me,
      callUser,
      leaveCall,
      answerCall,
      LogMeIn,
      roomID,
      setRoomID,
    }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
