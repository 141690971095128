import React, { useState, useContext, useEffect } from 'react';
import { SocketContext } from '../Context';
import IconMic from '../assets/images/Icon_mic.svg';
import IconMicOff from '../assets/images/Icon_mic_cancel.svg';
import IconCamera from '../assets/images/Icon_record.svg';
import IconCameraOff from '../assets/images/Icon_record_cancel.svg';
import IconExit from '../assets/images/Icon_call_cancel.svg';
import '../styles.css';

const Nav = () => {
  const { myVideo, leaveCall } = useContext(SocketContext);
  const [mic, setMic] = useState(true);
  const [camera, setCamera] = useState(true);
  const [disconnected, setDisconnected] = useState(false)

  function showLog(str) {
    console.log(str);
  }

  useEffect(() => {



    //if (stream) stream[0].enabled = !prevState.mic
  })

  // CONTROL LOCAL STREAM ============================

  const mutemic = () => {
    showLog("[mutemic]")
    setMic(!mic)
    if (myVideo.current) {
      const audiostream = myVideo.current.srcObject.getTracks().filter(track => track.kind === 'audio');
      showLog("audiostream: " + audiostream)
      audiostream[0].enabled = !mic
    }
    /*
    const stream = this.localVideoref.video.srcObject.getTracks().filter(track => track.kind === 'audio')
    this.setState(prevState => {
      if (stream) stream[0].enabled = !prevState.mic
      return {mic: !prevState.mic}
    })
    */
  }

  const mutecamera = () => {
    showLog("[mutecamera]")
    setCamera(!camera)
    if (myVideo.current) {
      const videostream = myVideo.current.srcObject.getTracks().filter(track => track.kind === 'video');
      showLog("videostream: " + videostream)
      videostream[0].enabled = !camera
    }
    /*
    const stream = this.localVideoref.video.srcObject.getTracks().filter(track => track.kind === 'video')
    this.setState(prevState => {
      if (stream) stream[0].enabled = !prevState.camera
      return {camera: !prevState.camera}
    })
    */
  }
  // =================================================

  const handleExit = () => {
    showLog("[handleExit]")
    leaveCall()
  }


  return (
    <div>
      <div className='toolbar-horizontal'>
        <img onClick={mutemic} className={mic && 'toolbar-icon-svg' || 'toolbar-icon-svg toolbar-icon-svg-off'} src={(mic) ? IconMic : IconMicOff} />
        <img onClick={mutecamera} className={camera && 'toolbar-icon-svg' || 'toolbar-icon-svg toolbar-icon-svg-off'} src={(camera) ? IconCamera : IconCameraOff} />
        <img onClick={handleExit} className='toolbar-icon-svg toolbar-icon-svg-red' src={ IconExit } />
      </div>
    </div>
  );
}

export default Nav;
