import React, { useContext } from 'react';
import { SocketContext } from './Context';
// import { Typography, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Login from './components/Login';
import VideoPlayer from './components/VideoPlayer';
import VideoChat from './components/VideoChat';
//import Sidebar from './components/Sidebar';
import Notifications from './components/Notifications';
import RCMlogoWhite from "./assets/images/rcm-logo-white.svg"


const useStyles = makeStyles((theme) => ({

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

const App = () => {
  const classes = useStyles();

  const { login, setLogin } = useContext(SocketContext);

  return (
    <div>
      {login && (
        <div>
          <div className="watermark">
            <img src={RCMlogoWhite}
              width="200"
              alt="rcm logo"
            />
          </div>
          <VideoChat />

          <Notifications />
        </div>
      ) || (
        <div>
          <Login />
        </div>
      )}
      </div>

  );
};

export default App;
