import React, { useState, useContext } from 'react';
import '../styles.css';
import { Button, TextField,Typography, Grid, Container, Paper } from '@material-ui/core';
import RCMlogo from "../assets/images/rcm-logo.svg";
import { SocketContext } from '../Context';
import NotificationPopup from "./NotificationPopup";



const Login = () => {

  const {me,login, setLogin, LogMeIn , name, setName, message, setMessage} = useContext(SocketContext);

  const handleSubmit = () => {
    console.log('[handleSubmit] name: ' + name);
    setLogin(true)
    LogMeIn()
    setMessage('');
  }


  return(
  <div className='App'>

    <div className="landing-brand">
      <img src={RCMlogo} width="157" height="76" alt="rcm logo" />
    </div>

    {message !== '' && (
      <NotificationPopup />
    )}

      <Container>
        <Grid item xs={12} md={6} className='margin'>
          <Typography gutterBottom variant="h6">Enter your name</Typography>

          <Paper className='margin'>

              <div>
                <TextField label="User Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
              </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
          <div>
            {name !== "" &&
              <div className='margin'>

                <Button variant="contained" color="primary" fullWidth onClick={() => handleSubmit()} >
                  Log in
                </Button>
              </div>
            }
          </div>
        </Paper>
        </Grid>
      </Container>


  </div>
  )
};

export default Login;
