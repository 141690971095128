import React, { useContext } from 'react';
import { Button, Container, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { SocketContext } from '../Context';
import '../styles.css';


const useStyles = makeStyles((theme) => ({

  margin: {
    marginTop: 20,
  },
  padding: {
    padding: 20,
  },
  paper: {
    padding: '10px 20px',
    border: '2px solid black',
  },
}));

const Notifications = () => {
  const { answerCall, call, callAccepted, message } = useContext(SocketContext);
  const classes = useStyles();
  return (
    <Container className="notifications-container">
      {(message !== '') && (
        <div> incoming message: { message } </div>
      )}
      <div>
      {call.isReceivingCall && !callAccepted && (
        <div className="vertical-center horizontal-center notifications-popup">
          <div>
          {/* {"call.isReceivingCall: " + call.isReceivingCall + " callAccepted: " + callAccepted} */}
          </div>
          {call.isReceivingCall && !callAccepted && (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className="notifications-header">{call.name} wants to join:</div>
              <Button onClick={answerCall} className="notifications-button">
                Accept
              </Button>
            </div>
          )}
        </div>
      )}
      </div>
    </Container>
  );
};

export default Notifications;
